<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-10 is-offset-1">
        <div class="columns is-multiline">
          <div class="column is-6">
            <SdSelect
              rules="required"
              label="School Class"
              v-model="schoolClassId"
              expanded
            >
              <option
                v-for="schoolClass in schoolClasses"
                :key="schoolClass.id"
                :value="schoolClass.id"
              >
                {{ schoolClass.name }}
              </option>
            </SdSelect>
          </div>
          <div class="column is-6">
            <SdSelect rules="required" label="Term" v-model="termId">
              <option v-for="term in terms" :key="term.id" :value="term.id">
                {{ term.title }}
              </option>
            </SdSelect>
          </div>
          <div class="column is-12">
            <div class="buttons is-right">
              <button
                class="button is-success"
                @click="fetchCummulativeScores()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-10 is-offset-1">
        <template v-if="loading">
          <loading />
        </template>
        <template v-else>
          <b-table :data="cummulativeScores" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{ cummulativeScores.indexOf(props.row) + 1 }}</b-table-column
            >

            <b-table-column label="Name" v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column label="First" v-slot="props">
              {{
                props.row.cummulativeResult[0]
                  ? props.row.cummulativeResult[0].total
                  : 0
              }}
            </b-table-column>

            <b-table-column label="Second" v-slot="props">
              {{
                props.row.cummulativeResult[1]
                  ? props.row.cummulativeResult[1].total
                  : 0
              }}
            </b-table-column>

            <b-table-column label="Third" v-slot="props">
              {{
                props.row.cummulativeResult[2]
                  ? props.row.cummulativeResult[2].total
                  : 0
              }}
            </b-table-column>

            <b-table-column label="Total" v-slot="props">
              {{ cummulativeTotal(props.row.cummulativeResult) }}
            </b-table-column>

            <b-table-column label="Average" v-slot="props">
              {{ cummulativeAverage(props.row.cummulativeResult) }}
            </b-table-column>
          </b-table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import TERMS from '../../../graphql/term/Terms.gql'
import SdSelect from '../../components/SdSelect'
import { crudNotification } from '../../assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  data() {
    return {
      user: {},
      schoolId: null,
      pageTitle: 'Cumulative Scores',
      termId: null,
      terms: [],
      schoolClassId: null,
      schoolClasses: [],
      cummulativeScores: [],
      scores: [],
      loading: false,
    }
  },
  apollo: {
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  components: {
    SdSelect,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Ate Assessment',
        route: `/school/${this.schoolId}/ate_assessment`,
      },
      {
        name: 'Cummulative Termly Total',
        route: `/school/${this.schoolId}/cumulative_termly_total`,
      },
    ])

    fetchUser().then((user) => {
      this.user = user
      this.fetchSchoolClasses()
    })
  },
  methods: {
    totals(result) {
      return [
        parseFloat(result[0] ? result[0].total : 0),
        parseFloat(result[1] ? result[1].total : 0),
        parseFloat(result[2] ? result[2].total : 0),
      ].filter((item) => item > 0)
    },
    cummulativeTotal(result) {
      if (this.totals(result).length === 0) return 0
      return this.totals(result)
        .reduce((a, b) => {
          return a + b
        })
        .toFixed(2)
    },
    cummulativeAverage(result) {
      const size = this.totals(result).length
      if (size === 0) return 0
      return (
        this.totals(result).reduce((a, b) => {
          return a + b
        }) / size
      ).toFixed(2)
    },
    fetchCummulativeScores() {
      this.loading = true
      this.$apollo
        .query({
          query: gql`
            query CummulativeScoresQuery($id: ID!, $termId: ID!) {
              cummulativeScores(id: $id, termId: $termId) {
                id
                regId
                name
                cummulativeResult
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolClassId),
            termId: parseInt(this.termId),
          },
        })
        .then((response) => {
          this.cummulativeScores = response.data.cummulativeScores
          this.loading = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
